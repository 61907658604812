import React from "react";
import { TabContent, TabSection } from "./TabContent";
import { TabItem } from "./TabItems";
import { DATA } from "../../data/data";

const SUBSECTION = "text-left px-5 md:px-20 pt-5";
const SUBSECTION_HEADING = "font-semibold pb-2";
const SUBSECTION_CONTENT = "px-5 md:px-10 font-serif-p font-light";

export function Travel() {
  return (
    <TabContent tab={TabItem.Travel} mainImage={DATA.images[TabItem.Travel]}>
      <TabSection heading="Where are we?">
        <div className={SUBSECTION}>
          <h3 className={SUBSECTION_HEADING}>Service</h3>
          <address className={SUBSECTION_CONTENT}>
            All Saints Church
            <br />
            Sinnington
            <br />
            North Yorkshire
            <br />
            YO62 6SF
          </address>
        </div>
        <div className={SUBSECTION}>
          <h3 className={SUBSECTION_HEADING}>Reception</h3>
          <address className={SUBSECTION_CONTENT}>
            Friars Hill Farm
            <br />
            Sinnington
            <br />
            North Yorkshire
            <br />
            YO62 6SL
          </address>
        </div>
        <div className="px-5 md:px-20 text-left font-serif-p font-light">
          <p className="pt-5">
            Sinnington is located off the A170 between Pickering and Helmsley on the edge of the beautfiul North
            Yorkshire Moors. The closest train station is Malton.
          </p>
          <p className="pt-5">
            If you are travelling to us on the day please be aware that whilst trains run frequently from London to
            York, they are infrequent from York to Malton! - If you arrive in Sinnington early, take a walk along the
            river, or relax in the beer garden at the Fox & Hounds!
          </p>
          <p className="pt-5">
            Parking will available at the farm where the reception is being held. It is a ten minute walk from the
            Church (and the pub!)
          </p>
          <p className="pt-5">
            We are in a rural location so please be advised that taxis must be booked in advance! There is a list of
            useful telephone numbers below.
          </p>
        </div>
      </TabSection>
      <TabSection heading="Contact Numbers">
        <div className={SUBSECTION}>
          <h3 className={SUBSECTION_HEADING}>In advance of the day</h3>
          <p className={SUBSECTION_CONTENT}>
            Penny - <PhoneNumber telephone="07585 328663" />
            <br />
            Fred - <PhoneNumber telephone="07365 528066" />
          </p>
        </div>
        <div className={SUBSECTION}>
          <h3 className={SUBSECTION_HEADING}>On the day</h3>
          <p className={SUBSECTION_CONTENT}>
            Henry - <PhoneNumber telephone="07768 789911" />
            <br />
            James - <PhoneNumber telephone="07823 405256" />
            <br />
            Dan - <PhoneNumber telephone="07809 114192" />
          </p>
        </div>
        <div className={SUBSECTION}>
          <h3 className={SUBSECTION_HEADING}>Taxis</h3>
          <p className={SUBSECTION_CONTENT}>
            Pickering Taxis - <PhoneNumber telephone="01751 617181" />
            <br />
            Ryedale’s Comfy Cars & Taxis - <PhoneNumber telephone="01439 377277" />
          </p>
        </div>
      </TabSection>
    </TabContent>
  );
}

interface PhoneNumberProps {
  telephone: string;
}

function PhoneNumber(props: PhoneNumberProps) {
  return (
    <a className="underline" href={`tel:${props.telephone.replace(" ", "")}`}>
      {props.telephone}
    </a>
  );
}

import { TabItem } from "../components/tabs/TabItems";
import { TenantData } from "./data_base";
import React from "react";
import monogram from "./images/Monogram.png";
import allsaints from "./images/All Saints.jpg";
import barney from "./images/Barney.jpg";
import fandh from "./images/F&H.jpg";
import flowers from "./images/Flowers.jpg";

export const DATA: TenantData = {
  date: new Date(2024, 7, 24),
  background: flowers,
  pallette: {
    activeBg: "bg-sky-300",
    hoverBg: "hover:bg-sky-400",
    overlayBg: "bg-sky-50",
    darkBg: "bg-sky-700",
  },
  names: "Fred & Penny",
  domain: "fredandpenny.co.uk",
  images: {
    [TabItem.Home]: { src: monogram, height: 300 },
    [TabItem.Travel]: { src: allsaints, height: 300 },
    [TabItem.Faq]: { src: fandh, height: 300 },
    [TabItem.Rsvp]: { src: barney, height: 300 },
    [TabItem.Gifts]: { src: barney, height: 300 },
  },
  faqs: [
    {
      question: "How do I RSVP for the wedding?",
      answer: ` 
          A: To RSVP for the wedding, please click on the RSVP link provided on the wedding website homepage and fill 
          out your details. 
      `,
    },
    {
      question: "Can I bring a plus one?",
      answer: ` 
          A: Unfortunately, due to space limitations, we are unable to accommodate any additional guests. 
      `,
    },
    {
      question: "What is the dress code for the wedding?",
      answer: ` 
          A: Suits or uniform for serving guests. The church is at the top of a steep hill and the reception will take
          place in a field, so please choose your dancing shoes wisely. Ladies - if you would like to wear a hat please do! 
      `,
    },
    {
      question: "What time does the wedding start?",
      answer: ` 
          A: The service will start at 2.30pm. If you are unable to attend the service but still wish to attend the
          reception this will start at 4pm. 
      `,
    },
    {
      question: "What time does the wedding end?",
      answer: ` 
          A: Carriages are at 12am.
      `,
    },
    {
      question: "Is there a gift registry for the wedding?",
      answer: ` 
          A: We appreciate any gifts but your presence on our special day is all that we could ask for. If you would like
          to give us a gift, we have created a gifts sections which has a link to our registry.
      `,
    },
  ],
  gifts: (
    <p className="font-serif-p font-light pt-5 px-5 md:px-16 text-left">
      We are so grateful for your presence on our special day, attending our wedding is the best gift we could ask for.
      However, should wish wish to buy a gift we have put together a wedding registry with{" "}
      <a className="underline text-blue-500" href="https://www.weddingpresentco.com/find-list/168732/">
        The Wedding Present Company
      </a>
      .
    </p>
  ),
};

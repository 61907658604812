import React from "react";
import { TabContent, TabSection } from "./TabContent";
import { TabItem } from "./TabItems";
import { DATA } from "../../data/data";

export function Rsvp() {
  return (
    <TabContent tab={TabItem.Rsvp} mainImage={DATA.images[TabItem.Rsvp]}>
      <TabSection heading="RSVP">
        <p className="font-serif-p font-light pt-5 px-20">
          Thank you for taking the time to reply to our wedding invitation. Please use the form{" "}
          <a className="text-blue-500 underline" href="https://forms.office.com/r/mS21W2gqJz?origin=lprLink">
            here
          </a>{" "}
          to RSVP.
        </p>
      </TabSection>
    </TabContent>
  );
}
